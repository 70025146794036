import cx from 'classnames';
import {
  PageProps,
  graphql,
} from 'gatsby';
import React, {
  useEffect,
  useState,
} from 'react';
import categoriesData from '../../content/data/categories.json';
import CatList from '../components/CategoryList/CatList';
import MdProjectList from '../components/ProjectsList/MdProjectsList';
import SEO from '../components/seo';
import useWindowDimensions from '../components/useWindowDimensions';
import { usePageContext } from '../contexts/pageContext';
import { ICategory } from '../models/categoryModel';
import styles from './category.module.scss';

const CategoryTemplate: React.FC<
  any
> = ({
  data,
  pageContext,
}: PageProps<{
  markdownRemark: any,
}>) => {
  const {height, width} =
    useWindowDimensions();
    const {setIsSideBarShown} = usePageContext()

    useEffect(() => {
      setIsSideBarShown(true)
      return () => {
      };
    }, []);
  // console.log('CATEGORY QUERY => ', data);
  const list = data.categories?.edges;
  const category = pageContext.category;
  const [cat, setCat] =
    useState<ICategory>({
      id: '',
      name: pageContext.category,
      color: 'black',
      comment: '',
    });

  const buildBG = (
    color: string,
    bg: string
  ) => {
    return (
      <svg
        className="w-screen z-0"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox={
          '0 0 ' + width + ' ' + height
        }
        width={width}
        height={height}
        opacity="0.34">
        <defs>
          <filter
            id="nnnoise-filter"
            x="-20%"
            y="-20%"
            width="140%"
            height="140%"
            filterUnits="objectBoundingBox"
            primitiveUnits="userSpaceOnUse"
            color-interpolation-filters="linearRGB">
            <feTurbulence
              type="turbulence"
              baseFrequency="0.05"
              numOctaves="4"
              seed="15"
              stitchTiles="stitch"
              x="0%"
              y="0%"
              width="100%"
              height="100%"
              result="turbulence"></feTurbulence>
            <feSpecularLighting
              surfaceScale="20"
              specularConstant="1"
              specularExponent="20"
              lighting-color={color}
              x="0%"
              y="0%"
              width="100%"
              height="100%"
              in="turbulence"
              result="specularLighting">
              <feDistantLight
                azimuth="3"
                elevation="95"></feDistantLight>
            </feSpecularLighting>
          </filter>
        </defs>
        <rect
          width={width}
          height={height}
          fill={bg}></rect>
        <rect
          width={width}
          height={height}
          fill={color}
          filter="url(#nnnoise-filter)"></rect>
      </svg>
    );
  };

  useEffect(() => {
    const catSel =
      categoriesData.categories.find(
        (c) => category === c.name
      );
    console.log(
      'CAT SELECTED => ',
      catSel
    );
    catSel && setCat(catSel);
  }, []);

  return (
    <>
      <SEO
        title={
          category
            .charAt(0)
            .toUpperCase() +
          category.slice(1) +
          ' | Gordo Labs'
        }
      />
      <div className="fixed right-0 h-screen w-full z-0">
        {buildBG(cat.color, '#fafafa')}
      </div>

      <article className="w-full justify-center font-charisma px-6 items-center min-h-screen pb-6 z-10 bg-transparent">
        <div
          className={
            'hidden md:block w-full'
          }>
          <CatList />
        </div>

        <section
          className={cx(
            styles.introSection,
            'w-full'
          )}>
          <h2
            style={{
              backgroundColor:
                cat.color,
            }}
            className="font-gordo md:text-3xl text-xl tracking-wider rounded-2xl px-4 py-2 md:mt-0 mt-20">
            {category
              .charAt(0)
              .toUpperCase() +
              category.slice(1)}
          </h2>
          <p>{cat.comment}</p>
        </section>

        <section
          className={cx(
            styles.base,
            ''
          )}>
          {list && (
            <MdProjectList list={list} />
          )}
        </section>
      </article>
    </>
  );
};

CategoryTemplate.defaultProps = {};

export default CategoryTemplate;

// dynamic page query, must occur within each post context
// $slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query ($category: String!) {
    categories: allMarkdownRemark(
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
      filter: {
        frontmatter: {
          categories: {eq: $category}
        }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(
              formatString: "MMMM Do, YYYY"
            )
            author
            title
            hero_image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt(pruneLength: 200)
          fields {
            slug
            type
          }
        }
      }
    }
  }
`;
